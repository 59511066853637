<template>
  <div id="app">
    <battery-finder />
  </div>
</template>

<script>
import batteryFinder from "./components/battery-finder.vue";

export default {
  name: "App",
  components: {
    batteryFinder,
  },
};
</script>

<style>
#battery-finder-app {
  width: 100%;
  margin-bottom: 1em;
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 1.2em;
}

#battery-finder-app > #app {
  width: 100%;
  margin: 1em 2em;
}

#battery-finder-app .batteryfinder-button {
  width: 100%;
  padding: 0.5em 1em;
  height: 3em;
  /*     background-color: #ffd82b; */
  background-color: #e5a800;
  color: white;
  box-shadow: #ccc 0 0 3em 0em;
  border: none;
}

#battery-finder-app .message {
  display: flex;
  justify-content: space-around;
  font-style: italic;
  color: #888;
}

.multiselect-search {
    padding: 5px 7px !important;
}

.modal-title {
  margin-right: auto;
}

.close-button {
  margin-left: auto;
}
</style>