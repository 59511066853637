// Manually updated from https://www.centurybatteries.co.nz/api/battery-finder/makes/carsuv4x4 Aug 2024

// This list does not include motorcycles, trucks, etc.

const initialMakes = JSON.parse(`[
        "ABARTH",
        "AC",
        "ACURA",
        "AIXAM",
        "ALFA ROMEO",
        "ALPINA",
        "ALPINE",
        "ALVIS",
        "AMC",
        "ANFINI",
        "ARMSTRONG SIDDELEY",
        "ASIA MOTORS",
        "ASTON MARTIN",
        "AUDI",
        "AUSTIN",
        "AUSTIN-HEALEY",
        "AUTO UNION",
        "AUTOBIANCHI",
        "AUTOZAM",
        "BEDFORD",
        "BENTLEY",
        "BERTONE",
        "BITTER",
        "BLMC",
        "BMW",
        "BOLWELL",
        "BOND",
        "BORGWARD",
        "BRICKLIN",
        "BRISTOL",
        "BUGATTI",
        "BUICK",
        "BYD",
        "CADILLAC",
        "CARBODIES",
        "CATERHAM",
        "CHECKER",
        "CHERY",
        "CHEVROLET",
        "CHRYSLER",
        "CITRO\u00CBN",
        "COMMER",
        "CUPRA",
        "DACIA",
        "DAEWOO",
        "DAF",
        "DAIHATSU",
        "DAIMLER",
        "DATSUN",
        "DE LOREAN",
        "DE TOMASO",
        "DESOTO",
        "DODGE",
        "DS",
        "EUNOS",
        "FERRARI",
        "FIAT",
        "FISKER",
        "FORD",
        "FOTON",
        "FPV",
        "FSM",
        "GAZ",
        "GEELY",
        "GENESIS",
        "GLAS",
        "GMC",
        "GOLDEN DRAGON",
        "GREAT WALL",
        "HAVAL",
        "HDT",
        "HILLMAN",
        "HOLDEN",
        "HONDA",
        "HSV",
        "HUMBER",
        "HUMMER",
        "HYUNDAI",
        "INEOS",
        "INFINITI",
        "INTERNATIONAL",
        "ISORIVOLTA",
        "ISUZU",
        "IVECO",
        "JAC",
        "JAGUAR",
        "JEEP",
        "JENSEN",
        "JMC",
        "KIA",
        "KOENIGSEGG",
        "KTM",
        "LADA",
        "LAMBORGHINI",
        "LANCIA",
        "LAND ROVER",
        "LANDWIND (JMC)",
        "LDV",
        "LEXUS",
        "LEYLAND",
        "LEYLAND-INNOCENTI",
        "LINCOLN",
        "LLOYD",
        "LOTUS",
        "LTI",
        "MAHINDRA",
        "MASERATI",
        "MAYBACH",
        "MAZDA",
        "MCLAREN",
        "MERCEDES-BENZ",
        "MERCURY",
        "MG",
        "MINELLI",
        "MINI",
        "MITSUBISHI",
        "MITSUOKA",
        "MORGAN",
        "MORRIS",
        "MOSKVICH",
        "NISSAN",
        "NSU",
        "OLDSMOBILE",
        "OPEL",
        "ORA",
        "PACKARD",
        "PAGANI",
        "PEUGEOT",
        "PIAGGIO",
        "PLYMOUTH",
        "POLESTAR",
        "PONTIAC",
        "PORSCHE",
        "PROTON",
        "RAM",
        "RAMBLER",
        "RELIANT",
        "RENAULT",
        "RENAULT TRUCKS",
        "RILEY",
        "ROLLS-ROYCE",
        "ROVER",
        "SAAB",
        "SAMSUNG",
        "SATURN",
        "SCION",
        "SEAT",
        "SHELBY",
        "SIMCA",
        "SINGER",
        "SKODA",
        "SKYWELL (KAIWO)",
        "SMART",
        "SOUEAST",
        "SPYKER",
        "SSANGYONG",
        "STANDARD",
        "STANDARD AUTOMOBILE",
        "STEYR",
        "STREETSCOOTER",
        "STUDEBAKER",
        "SUBARU",
        "SUNBEAM",
        "SUZUKI",
        "TALBOT",
        "TATA",
        "TAZZARI",
        "TESLA",
        "THINK",
        "TOYOTA",
        "TRIUMPH",
        "TULL",
        "TVR",
        "VAUXHALL",
        "VOLVO",
        "VW",
        "WIESMANN",
        "WOLSELEY",
        "YUGO",
        "ZASTAVA",
        "ZAZ",
        "ZENOS CARS",
        "ZHONGXING (ZX AUTO)"
    ]`);

export default initialMakes;
