import { createApp } from "vue";
import App from "./App.vue";

const Ecwid = window.Ecwid;

const app = createApp(App);

Ecwid.OnAPILoaded.add(function () {
  var widgets = Ecwid.getInitializedWidgets();
  if (!widgets.includes("SearchWidget")) {
    return;
  }

  var searchBox = document.querySelector("#ecwid_body [id^=my-search-]");
  var appEl = document.querySelector("#battery-finder-app");

  if (!searchBox) {
    return;
  }

  if (!appEl) {
    var el = document.createElement("div");
    el.id = "battery-finder-app";
    // el.addEventListener("send-enquiry", function (e) {
    //   console.log("send-enquiry event received", e);
    // });

    searchBox.parentNode.insertBefore(el, searchBox.nextSibling);
  }
  app.mount("#battery-finder-app");

  console.log("Ecwid storefront JS API has loaded");
});

// Ecwid.OnPageLoaded.add(function (page) {
//   console.log("ecwid page loaded: ", page);

//   if (page.type == "SEARCH") {
//     var searchBox = document.querySelector(".ec-search .search__field ");
//     var appEl = document.querySelector("#battery-finder-app");

//     if (searchBox && !appEl) {
//       var el = document.createElement("div");
//       el.id = "battery-finder-app";

//       searchBox.parentNode.insertBefore(el, searchBox.nextSibling);
//       app.mount("#battery-finder-app");
//     }
//   }
// });

// Ecwid.onPageSwitch.add(() => {
//   console.log("unmounting app");
//   app.unmount();
// });

export default Ecwid;
