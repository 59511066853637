<template>
  <div>
    <slot name="trigger" @click="openModal"></slot>
    <div class="modal" v-if="open">
      <div class="modal-overlay" @click="close"></div>
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">{{ title }}</h3>
          <button class="close-button" @click="close">&#10006;</button>
        </div>
        <slot name="header" />
        <div class="scroll-container">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BfModal",
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: "Modal",
    },
  },
  methods: {
    close() {
      this.$emit("update:open", false);
    },
    openModal() {
      this.$emit("update:open", true);
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.modal-content {
  background-color: white;
  padding: 20px;
  position: relative;
  z-index: 2;
  width: 80%;
  height: 80%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modal-title {
  margin: 0;
}

.close-button {
  cursor: pointer;
  font-weight: bold;
  background-color: transparent;
  border: none;
  color: lightgrey;
  /* font-size: 1.3em; */
}
.scroll-container {
  flex: 1;
  overflow-y: auto;
}
</style>
