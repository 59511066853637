<template>
  <bf-modal v-model:open="modalOpen" title="Battery Finder">
    <template v-slot:header>
      <div :class="{ 'bf-container': true, disabled: loading }">
        <div class="flex-row">
          <!-- <div>
            <Multiselect
              :add-tag-on="['enter', 'tab']"
              :can-clear="false"
              v-model="selections.category"
              :options="category"
              searchable
              placeholder="Select Category"
              :disabled="loading"
            >
              <template v-slot="{ option }">
                <div>{{ option }}</div>
              </template>
            </Multiselect>
          </div> -->
          <div>
            <Multiselect
              :add-tag-on="['enter', 'tab']"
              :can-clear="false"
              v-model="selections.make"
              :options="options.makes"
              searchable
              placeholder="Select Make"
              :disabled="loading"
            >
              <template v-slot="{ option }">
                <div>{{ option }}</div>
              </template>
            </Multiselect>
          </div>
          <div>
            <Multiselect
              :add-tag-on="['enter', 'tab']"
              :can-clear="false"
              v-model="selections.model"
              :options="options.models"
              searchable
              placeholder="Select Model"
              :disabled="loading || !selections.make"
              @change="
                selections.series = '';
                selections.year = '';
              "
            >
              <template v-slot="{ option }">
                <div>{{ option.name }}</div>
              </template>
            </Multiselect>
          </div>
          <!-- <div>
            <Multiselect
              :add-tag-on="['enter', 'tab']"
              :can-clear="false"
              v-model="selections.year"
              :options="options.years"
              searchable
              placeholder="Select Year"
              :disabled="
                loading ||
                !selections.make ||
                !selections.model ||
                !options.years.length
              "
            >
              <template v-slot="{ option }">
                <div>{{ option }}</div>
              </template>
            </Multiselect>
          </div> -->
          <!-- <div>
            <Multiselect
              :add-tag-on="['enter', 'tab']"
              :can-clear="false"
              v-model="selections.series"
              :options="options.series"
              searchable
              placeholder="Select Series"
              :disabled="
                loading ||
                !selections.make ||
                !selections.model ||
                !options.years.length
              "
            >
              <template v-slot="{ option }">
                <div>{{ option.name }}</div>
              </template>
            </Multiselect>
          </div> -->
        </div>
        <div v-if="loading" class="spinner"></div>
      </div>
      <!-- <div>
        {{ filteredVehicles.length }} of {{ vehicles.length }} Vehicles loaded
      </div> -->
      <hr />
    </template>
    <div class="table" v-if="selections.make && selections.model">
      <table v-if="filteredVehicles.length">
        <thead>
          <tr>
            <th>Make</th>
            <th>Model</th>
            <th>Year</th>
            <th>Series</th>
            <th>Chassis</th>
            <th>Engine Code</th>
            <th>Power (kW)</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="v of filteredVehicles" :key="v.VehicleId">
            <td>{{ v.Make }}</td>
            <td>{{ v.Model }}</td>
            <td>{{ v.YearFrom }} - {{ v.YearTo }}</td>
            <td>{{ v.Series }}</td>
            <td>{{ v.Chassis }}</td>
            <td>{{ v.EngineCode }}</td>
            <td>{{ v.Power }}</td>
            <td>
              <a
                v-if="isSendEnquiry"
                @click.prevent="viewBatteries(v)"
                class="view-batteries-button"
                >Send Enquiry</a
              >
              <a
                v-else
                @click.prevent="viewBatteries(v)"
                class="view-batteries-button"
                >View Batteries</a
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="message">
        No vehicles found for this selection.
        <a @click.prevent="sendEnquiry">Make an enquiry</a>
      </div>
    </div>
    <div v-else class="message">
      <div>No vehicles found. Select a make and model above.</div>
    </div>

    <template v-slot:trigger>
      <button @click="modalOpen = true" class="batteryfinder-button">
        Battery Finder
      </button>
    </template>
  </bf-modal>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import makeOptions from "../lib/initialMakes";
import bfModal from "./bf-modal.vue";
import axios from "axios";
import { orderBy, uniq } from "lodash";
// import lscache from "../lib/cacheToLocalStorage";

export default {
  computed: {
    isSendEnquiry() {
      // Send enquiry if the make is motorcycle or not a car
      return (
        this.selections.make?.toLowerCase().includes("motorcycle") ||
        this.selections.make?.toLowerCase().includes("atv")
      );
    },
  },
  components: {
    Multiselect,
    bfModal,
  },
  data() {
    return {
      modalOpen: false,
      loading: false,
      // makes: makeOptions,
      vehicles: {
        availableMakes: [],
        availableModels: [],
        availableYears: [],
        availableSeries: [],
      },
      filteredVehicles: [],
      selections: {
        make: "",
        model: "",
        year: "",
        series: "",
      },
      options: {
        makes: [],
        models: [],
        years: [],
        series: [],
      },
    };
  },
  created() {
    // Call fetchFromAPI to load data into options.makes when the script is initially run
    try { this.fetchFromAPI();
    }
    catch (error) {
      console.log ("Loading static makes");
      this.options.makes === makeOptions;
    }
  },
  watch: {
    selections: { handler: "filterVehicles", deep: true },
    "selections.make"() {
      this.selections.model = "";
      this.selections.year = "";
      this.selections.series = "";
      this.fetchFromAPI();
    },
    "selections.model"() {
      this.selections.year = "";
      this.selections.series = "";
      // this.fetchDetailsFromAPI();
      this.fetchFromAPI();
      // this.filterVehicles();
      // this.filterYears();
    },
    "selections.year"() {
      this.selections.series = "";
      this.fetchFromAPI();
    },
    "selections.series"() {
      this.fetchFromAPI();
    }
  },
  methods: {
    sendEnquiry(vehicle) {
      this.$emit("send-enquiry", vehicle);
      this.modalOpen = false;

      // Scroll to enquiry form and prefill details textarea with vehicle information.
      setTimeout(() => {
        const enquiryForm = document.querySelector(".sectorgbl-form-wrapper ");
        enquiryForm.scrollIntoView({ behavior: "smooth" });
        if (vehicle) {
          const details = document.querySelector(
            "textarea[placeholder=Details]"
          );
          details.value = `\n # Searched Vehicle: \nMake: ${
            vehicle.Make ?? ""
          } \nModel: ${vehicle.Model ?? ""}\nYear: ${
            vehicle.YearFrom ?? ""
          } - ${vehicle.YearTo ?? ""}\nSeries: ${
            vehicle.Series ?? ""
          }\nChassis: ${vehicle.Chassis ?? ""}\nPower: ${vehicle.Power ?? ""}`;
        }
        document.querySelector("input[placeholder^='First Name']")?.focus();
      }, 100);
    },
    async viewBatteries(vehicle) {
      if (this.isSendEnquiry) {
        this.sendEnquiry(vehicle);
        return;
      }

      try {
        this.loading = true;
        const url = `${process.env.VUE_APP_API_URL}/api/batteryFinder`;
        const { data } = await axios.get(`${url}?id=${vehicle.VehicleId}`);
        // console.log ("Data: ",data);

        if (data?.products?.length) {
          const products = orderBy(data.products, ["rating"], ["desc"]);
          
          if (products[0]?.id) {
            window.Ecwid.openPage("product", { id: products[0].id });
            return;
          } else {
            console.log("searching by titles: ", products);
            const titles = products.map(id => `"${id}"`).join(", ");

            window.Ecwid.openPage("search", {
              keyword: titles,
            });
          }
        } else {
          alert("No current battery options found. Please make an enquiry.");
          this.sendEnquiry(vehicle);
        }
      } finally {
        this.modalOpen = false;
        this.loading = false;
      }
    },
    filterVehicles() {
      console.log("filtering vehicles");
      if (!Array.isArray(this.vehicles)) {
        console.error("Vehicles is not an array:", this.vehicles);
        return;
      }

      this.filteredVehicles = this.vehicles;

      // Filter makes to get models
      if (this.selections.make) {
        this.filteredVehicles = this.filteredVehicles.filter(
          (v) => v.Make === this.selections.make
        );
        this.options.models = uniq(
          this.vehicles.map((v) => v.availableModels)
        );
      } else {
        this.options.models = uniq(
          this.vehicles.map((v) => v.availableModels)
        );
      }

      // Filter models to get years
      if (this.selections.model) {
        this.filteredVehicles = this.filteredVehicles.filter(
          (v) => v.Model === this.selections.model
        );
        this.options.years = uniq(
          this.filteredVehicles.map((v) => v.availableYears)
        );
      } else {
        this.options.years = uniq(
          this.vehicles.map((v) => v.availableYears)
        );
      }

      // Filter years to get series
      if (this.selections.year) {
        this.filteredVehicles = this.filteredVehicles.filter(
          (v) => v.Year === this.selections.year
        );
        this.options.series = uniq(
          this.filteredVehicles.map((v) => v.availableSeries)
        );
      } else {
        this.options.series = uniq(
          this.vehicles.map((v) => v.availableSeries)
        );
      }

      // Filter series to get vehicles
      if (this.selections.series) {
        this.filteredVehicles = this.filteredVehicles.filter(
          (v) => v.Vehicles === this.selections.series
        );
      }
    },
    async fetchDetailsFromAPI() {
      try {
        this.loading = true;

        const url = `${process.env.VUE_APP_API_URL}/api/searchDetails`;

        if (!this.selections) {
          this.loading = false;
          return;
        }
        const params = {
          category: "carsuv4x4",
          make: this.selections.make,
          model: this.selections.model,
          year: this.selections.year,
          series: this.selections.series,
        };

        const response = await axios.get(url, { params });

        if (response.data.Years) {
          console.log("Years response detected");
          this.vehicles = response.data.Years.map((years) => ({
            Make: this.selections.make,
            Model: this.selections.model,
            availableYears: years,
          }));
        }
        this.filterVehicles();
      } catch (error) {
        console.error("Error fetching makes:", error);
      } finally {
        this.loading = false;
      }
    },
    async fetchFromAPI() {
      try {
        this.loading = true;

        const url = `${process.env.VUE_APP_API_URL}/api/searchVehicles`;

        if (!this.selections) {
          this.loading = false;
          return;
        }
        const params = {
          category: "carsuv4x4",
          make: this.selections.make,
          model: this.selections.model,
          year: this.selections.year,
          series: this.selections.series,
        };
        // const cacheKey = `batteryfinder-make-${this.selections.make}`;
        // if (lscache(cacheKey)) {
        //   console.log("found in cache", cacheKey);
        //   this.vehicles = lscache(cacheKey);
        //   this.filterVehicles();
        //   return;
        // }

        const response = await axios.get(url, { params });

        if (response.data.Makes) {
          console.log("Makes response detected");
          this.vehicles = response.data.Makes.map((make) => ({
          availableMakes: make,
        }));
        //Load makes into dropdown ready for initial selection
        this.options.makes = uniq(
          this.vehicles.map((v) => v.availableMakes)
        );
        console.log("Makes loaded")
        } else if (response.data.Models) {
          console.log("Models response detected");
          this.vehicles = response.data.Models.map((model) => ({
            Make: this.selections.make,
            availableModels: model,
          }));
        } else if (response.data.Years) {
          console.log("Years response detected");
          this.vehicles = response.data.Years.map((years) => ({
            Make: this.selections.make,
            Model: this.selections.model,
            Years: years,
          }));
        } else if (response.data.Series) {
          console.log("Series response detected");
          this.vehicles = response.data.Series.map((series) => ({
            Make: this.selections.make,
            Model: this.selections.model,
            Year: this.selections.year,
            availableSeries: series,
          }))
        } else if (response.data.Vehicles) {
          console.log("Vehicles response detected");
          this.vehicles = response.data.Vehicles.map((vehicle) => ({
            Make: vehicle.Make,
            Model: vehicle.Model,
            YearFrom: vehicle.YearFrom,
            YearTo: vehicle.YearTo,
            Series: vehicle.Series,
            Chassis: vehicle.Chassis,
            Power: vehicle.KW,
            VehicleId: vehicle.VehicleId,
          }));
        }

        // lscache(cacheKey, this.vehicles);
        this.filterVehicles();
      } catch (error) {
        console.error("Error fetching makes:", error);
      } finally {
        this.loading = false;
      }
    },
    filterYears() {
      if (!this.selections.model) {
        this.options.years = [];
        return;
      }

      const modelVehicles = this.vehicles.filter(
        (v) => v.Model === this.selections.model
      );

      this.options.years = this.getYearRange(modelVehicles);
    },
    getYearRange(array) {
      // Filter out objects with falsy values for YearFrom and YearTo
      const validRanges = array.filter(
        (obj) => obj && obj.YearFrom !== undefined && obj.YearTo !== undefined
      );

      if (validRanges.length === 0) {
        return [];
      }

      // Find the minimum "YearFrom" and maximum "YearTo" values
      let minYearFrom = Infinity;
      let maxYearTo = -Infinity;

      for (let i = 0; i < validRanges.length; i++) {
        const range = validRanges[i];
        if (range.YearFrom && range.YearFrom < minYearFrom) {
          minYearFrom = range.YearFrom;
        }
        if (range.YearTo && range.YearTo > maxYearTo) {
          maxYearTo = range.YearTo;
        }
      }

      // Generate the array of all possible values between the minimum "YearFrom" and maximum "YearTo"
      const yearRange = [];
      for (let year = minYearFrom; year <= maxYearTo; year++) {
        yearRange.push(year);
      }

      return yearRange;
    },
  },
};
</script>



<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
.flex-row {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  justify-items: space-between;
  flex-wrap: wrap;
}

.flex-row > * {
  margin-bottom: 0.5em;
  margin-right: 0.5em;
  margin-left: 0.5em;
  min-width: 15em;
  flex-grow: 1;
}

.spinner {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner::after {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: spinner 1.2s linear infinite;
}

.bf-container.disabled {
  pointer-events: none;
}

.bf-container {
  position: sticky;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.spinner::after {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: spinner 1.2s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.batteryfinder-button {
  height: 34px;
  background-color: transparent;
  text-align: center;
  /* transform: translateY(-15px) !important; */
  border: solid 1px;
  margin-right: 0.5em;
}

#battery-finder-app .table table {
  width: 100%;
  background-color: #fefefe;
}
#battery-finder-app .view-batteries-button {
  background-color: darkgrey;
  padding: 0.5em;
  width: 5em;
  display: inline-block;
  cursor: pointer;
  color: white;
  text-align: center;
}
#battery-finder-app .table table tbody tr td {
  width: auto;
  border-top: solid lightgrey 1px;
  padding: 1em 0.5em;
}
</style>
